import Vue from 'vue'
import VueRouter from 'vue-router'
import lanhu_iphone13mini1 from '../views/lanhu_iphone13mini1/index.vue'

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'lanhu_iphone13mini1',
    component: lanhu_iphone13mini1
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
