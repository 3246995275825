<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  created() {
    this.redirectBasedOnDevice();
  },
  methods: {
    redirectBasedOnDevice() {
      if (
        /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        // 如果检测到是移动设备，跳转到手机端网址
      } else {
        window.location.href = "http://gage-us.com";
      }
    },
  },
};
</script>

<style lang="css" src="./assets/common.css"></style>
