<template>
  <div class="page flex-col">
    <div class="cover" v-if="sidebarshow" @click="closesidebar"></div>
    <div class="sidebar" v-if="sidebarshow">
      <img src="./assets/img/close.png" @click="closesidebar" class="sidebarclose" />
      <div @click="clickToJumpTo('#home')" class="text-s-1 top-1">Home</div>
      <div @click="clickToJumpTo('#vital1')" class="text-s-1">Edge</div>
      <div @click="clickToJumpTo('#limitation')" class="text-s-1">limitation</div>
      <div @click="clickToJumpTo('#Science')" class="text-s-1">Science</div>
      <div @click="connectionJump()" class="text-s-1">Login</div>
    </div>
    <div class="section_1 flex-col">
      <div class="group_1 flex-col">
        <div class="box_1 flex-col">
          <div class="block_1 flex-row justify-between">
            <span
              id="home"
              class="text_1"
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-delay="0"
              data-aos-once="false"
              >GAGE</span
            >
            <img
              @click="opensidebar"
              class="label_1"
              referrerpolicy="no-referrer"
              src="./assets/img/FigmaDDSSlicePNG3e79f5ff9905c2824c7f95303b9ca4df.png"
            />
          </div>
          <div class="block_2 flex-row justify-between">
            <span
              class="text_2"
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-delay="100"
              data-aos-once="false"
              >GAGE</span
            >
            <img
              class="label_2"
              referrerpolicy="no-referrer"
              src="./assets/img/FigmaDDSSlicePNG38ef0b2a15a50569eaf9c298de41e50f.png"
            />
          </div>
          <img
            class="image_1"
            referrerpolicy="no-referrer"
            src="./assets/img/FigmaDDSSlicePNGb512905aa5deba8e519ca4770bbdbee9.png"
            data-aos="flip-left"
            data-aos-duration="1000"
            data-aos-delay="1000"
            data-aos-anchor-placement="top-center"
            data-aos-once="false"
          />
          <span
            class="text_3"
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-delay="500"
            data-aos-once="false"
            >The&nbsp;Future&nbsp;of&nbsp;Gold&nbsp;Intelligent&nbsp;Trading</span
          >
        </div>
      </div>
    </div>
    <div class="section_2 flex-col">
      <div class="text-group_1 flex-col justify-between">
        <span
          id="vital1"
          class="text_4"
          data-aos="fade-right"
          data-aos-duration="2000"
          data-aos-delay="1500"
          data-aos-anchor-placement="top-center"
          data-aos-once="false"
          >GAGE&nbsp;——</span
        >
        <span
          class="text_5"
          data-aos="fade-right"
          data-aos-duration="2000"
          data-aos-delay="2000"
          data-aos-anchor-placement="top-center"
          data-aos-once="false"
          >Your&nbsp;passport&nbsp;to&nbsp;enter&nbsp;the&nbsp;global&nbsp;gold&nbsp;market</span
        >
      </div>
      <img
        class="image_2"
        referrerpolicy="no-referrer"
        src="./assets/img/FigmaDDSSlicePNG788bfe0930de94220291cad0d79fce14.png"
        data-aos="zoom-in-up"
        data-aos-duration="2000"
        data-aos-delay="0"
        data-aos-anchor-placement="top-center"
        data-aos-once="false"
      />
    </div>
    <div
      class="text-wrapper_1 flex-col"
      data-aos="fade-up"
      data-aos-duration="2000"
      data-aos-delay="0"
      data-aos-anchor-placement="top-center"
      data-aos-once="false"
    >
      <span class="paragraph_1">
        Our&nbsp;powerful&nbsp;AI&nbsp;engine&nbsp;analyzes&nbsp;the&nbsp;gold&nbsp;market&nbsp;prices&nbsp;of&nbsp;each&nbsp;country&nbsp;at&nbsp;an&nbsp;astonishing&nbsp;speed
        <br />
        Not&nbsp;only&nbsp;can&nbsp;it&nbsp;help&nbsp;you&nbsp;predict&nbsp;trends,&nbsp;but&nbsp;it&nbsp;can&nbsp;also&nbsp;help&nbsp;you&nbsp;buy&nbsp;and&nbsp;sell&nbsp;at&nbsp;the&nbsp;best&nbsp;time
        <br />
        Stable&nbsp;income&nbsp;acquisition.&nbsp;No&nbsp;matter&nbsp;where&nbsp;you&nbsp;are,&nbsp;GAGE&nbsp;will&nbsp;provide&nbsp;timely&nbsp;support&nbsp;for&nbsp;your&nbsp;investments.
      </span>
    </div>
    <div class="section_3 flex-col">
      <div
        id="limitation"
        class="text-group_2 flex-col"
        data-aos="flip-right"
        data-aos-duration="2000"
        data-aos-delay="0"
        data-aos-anchor-placement="top-center"
        data-aos-once="false"
      >
        <span class="text_6">GAGE</span>
        <span class="text_7"
          >Gold&nbsp;markets&nbsp;covering&nbsp;multiple&nbsp;countries&nbsp;worldwide</span
        >
        <span class="text_8">
          Enable&nbsp;you&nbsp;to&nbsp;leverage&nbsp;market&nbsp;changes&nbsp;in&nbsp;different&nbsp;regions&nbsp;to&nbsp;maximize&nbsp;your&nbsp;investment&nbsp;return.&nbsp;Our&nbsp;platform&nbsp;enables&nbsp;you&nbsp;to&nbsp;seamlessly&nbsp;switch&nbsp;between&nbsp;multiple&nbsp;markets&nbsp;and&nbsp;seize&nbsp;diverse&nbsp;opportunities.
        </span>
      </div>
      <div
        class="image-text_1 flex-col justify-between"
        data-aos="fade-down-left"
        data-aos-duration="2000"
        data-aos-delay="0"
        data-aos-anchor-placement="top-center"
        data-aos-once="false"
      >
        <img
          class="image_3"
          referrerpolicy="no-referrer"
          src="./assets/img/FigmaDDSSlicePNG89419f735b10733c9772ad1aec556b1a.png"
        />
        <span class="text-group_3">
          We&nbsp;understand&nbsp;the&nbsp;uncertainty&nbsp;of&nbsp;financial&nbsp;markets
        </span>
      </div>
      <img
        class="image_4"
        referrerpolicy="no-referrer"
        src="./assets/img/FigmaDDSSlicePNGcb91c5a8f14713a62271e25a0edbbbb5.png"
      />
      <div
        id="Science"
        class="text-group_4 flex-col justify-between"
        data-aos="fade-right"
        data-aos-duration="2000"
        data-aos-delay="0"
        data-aos-anchor-placement="top-center"
        data-aos-once="false"
      >
        <span class="text_9">GAGE</span>
        <span class="text_10"
          >Intelligent&nbsp;selection&nbsp;for&nbsp;gold&nbsp;trading</span
        >
      </div>
    </div>
    <div
      class="section_4 flex-col"
      data-aos="flip-left"
      data-aos-duration="2000"
      data-aos-delay="0"
      data-aos-anchor-placement="top-center"
      data-aos-once="false"
    >
      <div class="image-wrapper_1 flex-col">
        <img
          class="image_5"
          referrerpolicy="no-referrer"
          src="./assets/img/FigmaDDSSlicePNG58a9297ebe32001b182f89ecd38e81cc.png"
        />
      </div>
      <span class="text_11">
        Whether&nbsp;you&nbsp;are&nbsp;a&nbsp;beginner&nbsp;or&nbsp;a&nbsp;professional&nbsp;trader,&nbsp;GAGE&nbsp;will&nbsp;provide&nbsp;you&nbsp;with&nbsp;unique&nbsp;investment&nbsp;opportunities.
      </span>
    </div>
    <div
      class="section_5 flex-col"
      data-aos="flip-left"
      data-aos-duration="2000"
      data-aos-delay="600"
      data-aos-anchor-placement="top-center"
      data-aos-once="false"
    >
      <div class="image-wrapper_2 flex-col">
        <img
          class="image_6"
          referrerpolicy="no-referrer"
          src="./assets/img/FigmaDDSSlicePNG5f539ff3e11df06957b9deb05e0a3528.png"
        />
      </div>
      <span class="text_12">
        Our&nbsp;platform&nbsp;makes&nbsp;gold&nbsp;trading&nbsp;simple&nbsp;and&nbsp;exciting,&nbsp;so&nbsp;you&nbsp;can&nbsp;trade&nbsp;with&nbsp;confidence
      </span>
    </div>
    <div
      class="section_6 flex-col"
      data-aos="flip-left"
      data-aos-duration="2000"
      data-aos-delay="1000"
      data-aos-anchor-placement="top-center"
      data-aos-once="false"
    >
      <div class="image-wrapper_3 flex-col">
        <img
          class="image_7"
          referrerpolicy="no-referrer"
          src="./assets/img/FigmaDDSSlicePNG3635b355e5f3250e5d286639119f5a93.png"
        />
      </div>
      <span class="text_13">
        Our&nbsp;AI&nbsp;intelligence&nbsp;will&nbsp;always&nbsp;be&nbsp;by&nbsp;your&nbsp;side,&nbsp;helping&nbsp;you&nbsp;achieve&nbsp;stable&nbsp;returns.
      </span>
    </div>
    <span
      class="text_14"
      data-aos="fade-right"
      data-aos-duration="2000"
      data-aos-delay="0"
      data-aos-anchor-placement="top-center"
      data-aos-once="false"
      >GAGE</span
    >
    <div class="section_7 flex-col">
      <div class="section_8 flex-col">
        <span
          class="text_15"
          data-aos="fade-right"
          data-aos-duration="2000"
          data-aos-delay="0"
          data-aos-anchor-placement="top-center"
          data-aos-once="false"
          >The&nbsp;Time&nbsp;Freedom&nbsp;of&nbsp;Gold&nbsp;Intelligent&nbsp;Trading</span
        >
        <img
          class="image_8"
          referrerpolicy="no-referrer"
          src="./assets/img/FigmaDDSSlicePNGb61f8c423e63f97e28787138f5f74d61.png"
          data-aos="fade-right"
          data-aos-duration="2000"
          data-aos-delay="0"
          data-aos-anchor-placement="top-center"
          data-aos-once="false"
        />
        <span
          class="text_16"
          data-aos="fade-right"
          data-aos-duration="2000"
          data-aos-delay="0"
          data-aos-anchor-placement="top-center"
          data-aos-once="false"
        >
          Our&nbsp;platform&nbsp;uniquely&nbsp;provides&nbsp;you&nbsp;with&nbsp;flexible&nbsp;timing&nbsp;options&nbsp;to&nbsp;suit&nbsp;different&nbsp;investment&nbsp;objectives&nbsp;and&nbsp;risk&nbsp;appetites.&nbsp;Whether&nbsp;you&nbsp;are&nbsp;a&nbsp;short-term&nbsp;trader&nbsp;or&nbsp;a&nbsp;long-term&nbsp;investor,&nbsp;GAGE&nbsp;will&nbsp;meet&nbsp;your&nbsp;needs.
        </span>
      </div>
    </div>
    <div class="tailStyle">
      <div class="middleTail">
        <div class="rightContent">
          <!-- <div class="theFirstPiece">
            <div class="firstTitle">ETHER</div>
            <div class="theFirstPieceOfContent">
              <ul>
                <li>Grants</li>
                <li>Generator</li>
                <li>Careers</li>
                <li>Disclaimer</li>
              </ul>
            </div>
          </div> -->
          <div class="theFirstPiece">
            <div class="firstTitle">GAGE</div>
            <div class="theFirstPieceOfContent">
              <ul>
                <li @click="clickToJumpTo('#home')">Home</li>
                <li @click="clickToJumpTo('#vital1')">Edge</li>
                <li @click="clickToJumpTo('#limitation')">limitation</li>
                <li @click="clickToJumpTo('#Science')">Science</li>
                <li @click="connectionJump()">Login</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="tailLeftSide">
          <div class="tailLogo">
            <img src="./assets/img/pic_logo@2x.png" alt="" />
          </div>
          <div class="tailIcontitle">
            <div class="tailText">Managed by Artificial Intelligence</div>
            <div class="tailIcon">
              <ul>
                <li>
                  <img src="./assets/img/ic_you@2x.png" alt="" />
                </li>
                <li>
                  <img src="./assets/img/ic_tw@2x.png" alt="" />
                </li>
                <li>
                  <img src="./assets/img/ic_g@2x.png" alt="" />
                </li>
                <li>
                  <img src="./assets/img/ic_aa@2x.png" alt="" />
                </li>
                <li>
                  <img src="./assets/img/ic_mao@2x.png" alt="" />
                </li>
                <li>
                  <img src="./assets/img/ic_feiji@2x.png" alt="" />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="tailBackground">
          <img src="./assets/img/pic_chiuzi@2x.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      sidebarshow: false,
      constants: {},
    };
  },
  methods: {
    clickToJumpTo(anchorId) {
      document.querySelector(anchorId).scrollIntoView({ behavior: "smooth" });
    },
    connectionJump() {
      window.location.href = "https://gage-us.cc/";
    },
    opensidebar() {
      this.sidebarshow = true;
    },
    closesidebar() {
      this.sidebarshow = false;
    },
  },
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />
